.treeNode {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    overflow: hidden;
}

.treeNodeCollapsed {
    max-height: 0;
    opacity: 0;
}

.treeNodeExpanded {
    max-height: 1000px;
    opacity: 1;
}