.my__header {
  font-family: DanteSansTextW;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.my__header a {
  text-decoration: none;
  color: inherit;
}

.my__header button {
  text-transform: none;
  background-color: transparent;
  background-image: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
}

.my__header ul {
  list-style: none;
  padding-left: 0px;
}

.my__forceCommunityThemeHeaderCompact {
  padding-left: 20px;
  padding-right: 20px;
}

.my__forceCommunityThemeHeaderBase {
  color: var(--lwc-colorTextLink, rgb(17, 17, 17));
  width: 100%;
  position: relative;
  z-index: 5;
}

.my__themeBgImage {
  z-index: 1;
  background: rgb(29, 31, 33);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.my__themeBottomBorder {
  background-color: rgba(196, 7, 43, 0);
  
  width: 100%;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.my__themeHeaderInner {
  width: 1440px;
  margin: 0 auto;
  padding-top: 13px;
  padding-bottom: 13px;

  opacity: 1;
  display: flex;
  position: relative;
  z-index: 3;
  align-items: center;
  height: 100%;
  min-height: 34px;
  max-width: 100%;
}

.my__themeLogo {
  display: flex;
  order: 2;
}

@media only screen and (min-width: 48em) {
  .my__themeLogo {
    margin-right: 40px;     
  }

  .my__themeLogo,
  .my__themeNav,
  .my__themeUtils {
    order: unset;     
  }
}

.my__forceCommunityThemeLogo {
  display: inline-block;
}

.my__logoImage {
  width: 155px;
  height: 51px;

  background-image: var(--lwc-brandLogoImage, url(../../../public/logo.png));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.my__slds-assistive-text {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  text-transform: none !important;
  white-space: nowrap !important;
}

.my__themeNav {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  order: 1;
}

.my__nav {
  justify-content: center;
  display: flex;
  margin: 0px;
}

.my__nav > li,
.my__themeProfileMenu {
  display: inline-block;
  color: white;
  background: transparent;
  border-bottom: 2px solid rgba(196, 7, 43, 0);
  font-size: 13px;
}

.my__nav > li > button,
.my__nav > li > a > button {
  padding: .75rem;
  cursor: pointer;
}

.my__nav > li > button:hover,
.my__nav > li > a > button:hover,
.my__themeProfileMenu:hover {
  color: rgb(249, 81, 78);
}

.my__nav .my__nav__icon {
  display: inline-block;
  font-size: 1.25rem;
}

.my__nav > li > button:focus + ul,
.my__nav > li > ul:focus,
.my__nav > li > ul:hover,  
.my__themeProfileMenu:focus + ul,
.my__themeUtilsContainer > ul:focus,
.my__themeUtilsContainer > ul:hover {
  transform: scaleY(1);
}

.my__nav > li > ul {
  transform: scaleY(0);
  transition: all 0.2s ease-in-out;
  transform-origin: 0 0;
  position: absolute;
  background-color: white;
  color: black;
  min-width: 11rem;
  outline: 1px solid rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
}

.my__nav > li > ul > li > a {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.my__nav > li > ul > li > a:hover,
.my__themeUtilsContainer > ul > li > a:hover {
  background-color: #fdf2f5;
}

.my__themeUtils {
  flex-grow: 0;
  text-align: right;
  padding-left: 60px;
  order: 3;
}

.my__themeUtilsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.my__themeUtilsContainer > ul {
  transform: scaleY(0);
  position: absolute;
  background-color: white;
  color: black;
  min-width: 6rem;
  outline: 1px solid rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
  border-radius: .25rem;
  padding: 0.25rem 0;
  top: 0;
  margin-top: 65px;
}

.my__themeUtilsContainer > ul > li > a {
  display: block;
  padding: 0.5rem .75rem;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s linear;
  font-size: .75rem;
  z-index: 1;
  position: relative;
}

.my__themeUtilsContainer > ul > li:first-child {
  border-bottom: 1px solid #F0F0F0;
}

.my__themeAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.my__themeProfileMenu {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  margin-left: 5px;
}

.my__themeUtilsContainer > ul:before {
  left: auto;
  right: 1.5rem;
  top: -.5rem;
  margin-right: -.5rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  transform: rotate(45deg);
  content: '';
  background-color: inherit;
  margin-left: -.5rem;
}

.my__themeUtilsContainer > ul:after {
  left: auto;
  right: 1.5rem;
  top: -.5rem;
  margin-right: -.5rem;
  box-shadow: -1px -1px 0 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
  width: 1rem;
  height: 1rem;
  position: absolute;
  transform: rotate(45deg);
  content: '';
  background-color: inherit;
  margin-left: -.5rem;
}